import { InteractionStatus } from "@azure/msal-browser";
import * as mr from "@azure/msal-react";
import React, { useEffect } from "react";
import { shallowEqual } from "react-redux";

import { Landing } from "@/components/signin/Landing";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { setB2CAuthority } from "@/slice/UserSlice";
import { isProd } from "@/util/app";
import { LogoutRedirect } from "@/util/auth";
import { UserTypes } from "@/util/b2c";

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export const AuthContext = ({ children }: Props) => {
  const { instance, accounts, inProgress } = mr.useMsal();
  const isAuthenticated = mr.useIsAuthenticated();
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.user, shallowEqual);
  const b2cAccounts = accounts.filter(
    (account) => account.environment == "syneraicustomers.b2clogin.com"
  );

  useEffect(() => {
    if (isAuthenticated) {
      if (b2cAccounts.length > 0) {
        const account = b2cAccounts[0];
        instance.setActiveAccount(account);
        const claims: any = account.idTokenClaims;
        const tfp: string = claims["tfp"] ?? "";
        if (tfp.toUpperCase() == "B2C_1_SYNERAIINTERNAL") {
          dispatch(setB2CAuthority(UserTypes.Staff));
        } else if (!isProd && tfp.toUpperCase() == "B2C_1_SYNERAIE2ETEST") {
          dispatch(setB2CAuthority(UserTypes.E2E));
        } else {
          return;
        }
      } else {
        /* Staff members may encounter unusual login scenarios if they log directly
         * into the B2C tenant via Azure Portal, since this creates a valid B2C
         * token that is not associated with an acceptable B2C user flow.
         * The workaround of logging out the user forces a clean state.
         */
        inProgress == InteractionStatus.None &&
          LogoutRedirect(userState.B2CAuthority, dispatch);
      }
    }
  }, [
    b2cAccounts,
    dispatch,
    inProgress,
    instance,
    isAuthenticated,
    userState.B2CAuthority,
  ]);

  return (
    <>
      <mr.AuthenticatedTemplate>{children}</mr.AuthenticatedTemplate>
      <mr.UnauthenticatedTemplate>
        <Landing />
      </mr.UnauthenticatedTemplate>
    </>
  );
};
