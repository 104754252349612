import { useAccount } from "@azure/msal-react";
import Image from "next/image";
import React, { PropsWithChildren } from "react";
import { shallowEqual } from "react-redux";

import { useAppDispatch, useAppSelector } from "@/hooks";
import { LogoutRedirect } from "@/util/auth";

const ForkLayout = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.user, shallowEqual);
  const account = useAccount();
  const email = !!account?.idTokenClaims
    ? account.idTokenClaims["emails"]?.at(0)?.replace(/@synerai.com/, "")
    : "";

  return (
    <div className="w-screen h-screen relative overflow-hidden select-none">
      <div className="absolute inset-x-0 top-0 h-12 bg-gray-800">
        <div className="w-full h-full flex items-center justify-between px-4">
          <div className="flex gap-4">
            <Image src="/img/fork.png" alt="fork-logo" width="24" height="24" />
            <span className="font-bold text-white text-xl">Fork</span>
          </div>
          <div className="flex items-center gap-4">
            <div className="text-sm sm:text-base font-semibold text-gray-200 pb-[2px]">
              {email}
            </div>
            <button
              className="border border-white w-28 h-8 rounded-full font-bold text-white hover:bg-gray-500"
              onClick={() => LogoutRedirect(userState.B2CAuthority, dispatch)}
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 top-12">
        <div className="absolute inset-0 overflow-auto">{children}</div>
      </div>
    </div>
  );
};

export default ForkLayout;
