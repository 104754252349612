import { MsalProvider } from "@azure/msal-react";
import { NextComponentType, NextPageContext } from "next/types";
import React from "react";

import { msalInstance } from "@/util/auth";
import { RouteType } from "@/util/Routes";

import { AuthContext } from "./AuthContext";
import ForkLayout from "./ForkLayout";
import { UserAccess } from "./UserAccess";

export interface MsalLayoutProps {
  Component: NextComponentType<NextPageContext, any, any>;
  pageProps: {};
  routeType: RouteType;
}

const MsalLayout = ({ routeType, Component, pageProps }: MsalLayoutProps) => (
  <MsalProvider instance={msalInstance}>
    {routeType == RouteType.Unauthenticated ? (
      <Component {...pageProps} />
    ) : (
      <AuthContext>
        <UserAccess>
          <ForkLayout>
            <Component {...pageProps} />
          </ForkLayout>
        </UserAccess>
      </AuthContext>
    )}
  </MsalProvider>
);

export default MsalLayout;
