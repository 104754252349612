import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";

import { LoadingAnimation } from "@/components/ui/LoadingAnimation";
import { useAppDispatch } from "@/hooks";
import { HandleLogin } from "@/util/auth";
import { UserTypes } from "@/util/b2c";

export const Landing = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (inProgress == InteractionStatus.None && !isAuthenticated) {
      HandleLogin(dispatch, UserTypes.Staff);
    }
  }, [dispatch, inProgress, isAuthenticated]);

  return (
    <div className="w-screen h-screen">
      <LoadingAnimation />
    </div>
  );
};
