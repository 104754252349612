import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import React, { ReactNode } from "react";
import { shallowEqual } from "react-redux";

import { LoadingAnimation } from "@/components/ui/LoadingAnimation";
import { loginRequest } from "@/config/authConfig";
import { useAppSelector } from "@/hooks";

interface Props {
  children: ReactNode;
}

export const UserAccess = ({ children }: Props) => {
  const userState = useAppSelector((state) => state.user, shallowEqual);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        authority: userState.B2CAuthority,
        scopes: loginRequest.scopes,
      }}
      errorComponent={(error) => (
        <div className="grid w-screen h-screen place-content-center">
          <h1 className="text-base font-semibold">
            An Error Occurred: {error.error?.errorCode}
          </h1>
        </div>
      )}
      loadingComponent={() => (
        <LoadingAnimation message="Authentication in progress" />
      )}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};
